
























































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image, PullRefresh, Toast } from "vant";
import ActorList from "@/views/ProjectManagement/components/ActorList.vue";
import WonderfulList from "@/views/ProjectManagement/components/Wonderful.vue";
import ProBriefing from "@/views/ProjectManagement/components/Briefing.vue";
import ProRecommend from "@/views/ProjectManagement/components/ProRecommend.vue";
import TopImg from "@/views/ProjectManagement/components/TopImg.vue";
import SingleRow from "@/views/ProjectManagement/components/SingleRow.vue";
import DoubleRow from "@/views/ProjectManagement/components/DoubleRow.vue";
import SlideSwipe from "@/views/ProjectManagement/components/SlideSwipe.vue";

@Component({
  components: {
    [Image.name]: Image,
    [PullRefresh.name]: PullRefresh,
    ActorList,
    WonderfulList,
    ProBriefing,
    ProRecommend,
    TopImg,
    SingleRow,
    DoubleRow,
    SlideSwipe,
  },
})
export default class ExternalProjectManagement extends Mixins(Mixin) {
  id = "";
  active = 0;
  isLoading = false;
  dataInfo: cms.SubjectDto = {
    shareDescribe: "",
    shareImg: "",
    specialTitle: "",
  };

  mounted(): void {
    // 非首次进入页面时需要在 mounted 中刷新页面
    if (this.ExternalAccessModule.isExternalAccessReady) {
      this.updateData();
    }
    let safeAreaHeight =
      window.innerHeight - document.documentElement.clientHeight;
    document.body.style.paddingBottom = `${safeAreaHeight}px`;
  }

  /**
   * 外部跳转 H5 异步获取 token、channel 的异步请求完成后执行
   * 一定在 mounted 之后
   */
  @Watch("ExternalAccessModule.isExternalAccessReady")
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.updateData();
    }
  }

  updateData(): void {
    this.id = this.$route.query.id ? String(this.$route.query.id) : "";
    this.getData();
  }

  onClickLeft(): void {
    if (this.ChannelModule.channel === "plat_h5") {
      this.$router.go(-1);
    } else {
      this.jsBridge("back", "");
    }
  }

  onRefresh(): void {
    this.isLoading = true;
    this.getData();
  }

  getData(): void {
    this.$api.cmsApi.subject.findSubjectInfoById(
      this.id,
      ({ data }) => {
        this.dataInfo = data;
        this.isLoading = false;
        window.document.title = data.specialTitle || "爱演出 爱生活-保利票务";
        this.SpecialManagementModule.SET_Special({
          commodityId: data.commodityId,
          commodityKeywords: data.commodityKeywords,
          productId: data.productId,
          productKeywords: data.productKeywords,
          subjectCommodity: data.subjectCommodity,
          subjectProduct: data.subjectProduct,
          openButtonImg: data.openButtonImg,
          closeButtonImg: data.closeButtonImg,
        });
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("isShowShare", "1");
          window.startShare = this.shareDate;
        }
      },
      () => {
        this.isLoading = false;
        window.document.title = "爱演出 爱生活-保利票务";
      }
    );
  }

  shareDate(): void {
    let url = `${location.origin}${location.pathname}#/project-management?id=${this.id}`;
    let title = this.dataInfo.specialTitle || "";
    let shareDescribe = this.dataInfo.shareDescribe || "";
    let shareImg = this.dataInfo.shareImg || "";
    this.jsBridge(
      "share",
      `title%&@/${title}%&@,desc%&@/${shareDescribe}%&@,picUrl%&@/${shareImg}%&@,url%&@/${url}`
    );
  }
}
