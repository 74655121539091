var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-management",staticStyle:{"overflow-y":"auto","-webkit-overflow-scrolling":"touch"}},[_c('div',{staticClass:"p-management",style:({ backgroundColor: _vm.dataInfo.backgroundColor })},[_c('van-pull-refresh',{attrs:{"success-text":"刷新成功"},on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[(_vm.dataInfo.images && _vm.dataInfo.images.headImg)?_c('top-img',{attrs:{"img-list":_vm.dataInfo.images.headImg}}):_vm._e(),(_vm.dataInfo.personList && _vm.dataInfo.personList.length > 0)?_c('actor-list',{attrs:{"actor-list":_vm.dataInfo.personList,"title-color":_vm.dataInfo.wordsColor}}):_vm._e(),(_vm.dataInfo.highlightsImg)?_c('wonderful-list',{attrs:{"wonderful-list":_vm.dataInfo.highlightsImg,"title-color":_vm.dataInfo.wordsColor}}):_vm._e(),(_vm.dataInfo.introduceMessage)?_c('pro-briefing',{attrs:{"introduce-message":_vm.dataInfo.introduceMessage,"title-color":_vm.dataInfo.wordsColor,"content-img":_vm.dataInfo.introduceTitleImg}}):_vm._e(),(_vm.dataInfo.images)?[(
            _vm.dataInfo.images.singleRowImg &&
            _vm.dataInfo.images.singleRowImg.length > 0
          )?_c('single-row',{attrs:{"img-list":_vm.dataInfo.images.singleRowImg}}):_vm._e(),(
            _vm.dataInfo.images.biserialImg &&
            _vm.dataInfo.images.biserialImg.length > 0
          )?_c('double-row',{attrs:{"img-list":_vm.dataInfo.images.biserialImg}}):_vm._e(),(
            _vm.dataInfo.images.crosswiseImg &&
            _vm.dataInfo.images.crosswiseImg.length > 0
          )?_c('slide-swipe',{staticClass:"slide-swipe",attrs:{"img-list":_vm.dataInfo.images.crosswiseImg}}):_vm._e()]:_vm._e(),(
          _vm.SpecialManagementModule.subjectCommodity ||
          _vm.SpecialManagementModule.subjectProduct
        )?_c('pro-recommend',{attrs:{"content-img":_vm.dataInfo.contentImg,"content-type":_vm.dataInfo.contentType,"title-color":_vm.dataInfo.wordsColor}}):_vm._e(),(_vm.dataInfo.images && _vm.dataInfo.images.baseImg)?_c('top-img',{attrs:{"img-list":_vm.dataInfo.images.baseImg}}):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }