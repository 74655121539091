




















import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import UserCheck from "@/views/Login/UserCheck.vue";
import wx from "weixin-js-sdk";
import { SlideCheck } from "@/types";
@Component({
  components: {
    UserCheck,
  },
})
export default class ExternalUserCheck extends Mixins(Mixin) {
  public showInfo: string[] = [];

  @Watch("ExternalAccessModule.isExternalAccessReady")
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.openUserCheck();
    }
  }
  created(): void {
    let showInfo: any = this.$route.query.showInfo;
    try {
      this.showInfo = JSON.parse(showInfo);
    } catch (e) {}
  }
  mounted(): void {
    if (this.ExternalAccessModule.isExternalAccessReady) {
      this.openUserCheck();
    }
  }
  /**
   * 打开滑块验证码
   */
  openUserCheck(): void {
    this.$nextTick(() => {
      let userCheck = this.$refs["user-check"] as UserCheck;
      if (userCheck) {
        userCheck.resetData();
      }
    });
  }
  // 人机校验对话框确定
  getUserToken(data: order.AliSliderCaptchaDto): void {
    try {
      const params: any = this.$route.query.params;
      const form = JSON.parse(params);
      this.$api.orderApi.ticketOrder.lockSeatChoose(
        {
          aliSliderCaptchaDto: data,
          ...form,
        },
        ({ data }) => {
          if (this.ChannelModule.channel == "plat_wechat_miniapp") {
            const theaterId = this.$route.query.theaterId;
            wx.miniProgram.redirectTo({
              url: `/pagesOrder/confirmOrder/confirmOrder?uuid=${data}${
                theaterId !== "40" ? "$theaterId=" + theaterId : ""
              }`,
            });
          } else if (this.ChannelModule.channel == "douyin_miniapp") {
            window.tt.miniProgram.redirectTo({
              url: "/pages/confirm/confirm?uuid=" + data,
            });
          }
        },
        (error) => {
          this.$toast(error);
          setTimeout(() => {
            if (this.ChannelModule.channel == "plat_wechat_miniapp") {
              wx.miniProgram.navigateBack();
            } else if (this.ChannelModule.channel == "douyin_miniapp") {
              window.tt.miniProgram.navigateBack();
            }
          }, 2000);
        }
      );
    } catch (e) {
      this.$toast("失败");
      setTimeout(() => {
        if (this.ChannelModule.channel == "plat_wechat_miniapp") {
          wx.miniProgram.navigateBack();
        } else if (this.ChannelModule.channel == "douyin_miniapp") {
          window.tt.miniProgram.navigateBack();
        }
      }, 2000);
    }
  }
  checkFail(code: any): void {
    this.$toast("校验失败," + code);
  }
}
