




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import ProDetail from "@/views/ProjectDetail/ProDetail.vue";

@Component({
  components: {
    ProDetail,
  },
})
export default class ExternalAccessProjectDetailPage extends Mixins(Mixin) {}
