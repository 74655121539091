









































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Watch } from "vue-property-decorator";
import LinkCenter from "@/views/Mine/MoreServices/LinkCoupon/components/LinkCenter.vue";
import { Dialog, Image } from "vant";
@Component({
  components: {
    LinkCenter,
    [Image.name]: Image,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class ExternalLinkCoupon extends Mixins(Mixin) {
  public id: string | (string | null)[] = "";
  public couponList: Array<cms.CouponDetailDto> = [];
  public linkInfo: cms.LinkActivityCouponResponse = {};
  public showRule = false;
  public showOver = false;

  /**
   * 外部跳转 H5 异步获取 token、channel 的异步请求完成后执行
   * 一定在 mounted 之后
   */
  @Watch("ExternalAccessModule.isExternalAccessReady")
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.updateData();
    }
  }

  mounted(): void {
    // 非首次进入页面时需要在 mounted 中刷新页面
    if (this.ExternalAccessModule.isExternalAccessReady) {
      this.updateData();
    }
  }

  updateData(): void {
    this.id = this.$route.query.id;
    if (this.id) {
      this.getLinkCoupons();
    }
  }

  /* 链接活动信息*/
  getLinkCoupons(): void {
    this.$api.cmsApi.lucky.getLinkActivity(this.id as string, ({ data }) => {
      if (
        this.ChannelModule.channel === "plat_ios" ||
        this.ChannelModule.channel === "plat_android"
      ) {
        this.jsBridge("isShowShare", "1");
        window.startShare = this.share;
      }
      this.linkInfo = data;
      window.document.title = data.activityName
        ? data.activityName
        : "爱演出 爱生活-保利票务";
      /*  活动待上架/已下架/已结束 */
      if (data.activityState === "0" || data.activityState === "2") {
        this.showOver = true;
      }
    });
  }
  share(): void {
    let url = `${location.origin}${location.pathname}#/coupon-link?id=${this.id}`;
    this.jsBridge(
      "share",
      `title%&@/${this.linkInfo.activityName}%&@,desc%&@/你的好友邀你一起领取优惠券%&@,picUrl%&@/${this.linkInfo.headImageUrl}%&@,url%&@/${url}`
    );
  }
}
