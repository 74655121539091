




import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import Article from "@/views/Article/components/Article.vue";

@Component({
  components: { Article },
})
export default class ExternalAccessArticlePage extends Mixins(Mixin) {
  mounted(): void {
    // 因为获取文章的接口不需要 token 和 channel 信息，所以不需要等 code 换外部跳转信息
    (this.$refs["article"] as Article).refreshData();
  }
}
