








import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import SelectArea from "@/components/SelectArea/SelectArea.vue";
import wx from "weixin-js-sdk";

declare type ProductSource = "POLY" | "DM" | "MY" | "MH" | "XY";

@Component({
  components: { SelectArea },
})
export default class ExternalAccessSelectAreaPage extends Mixins(Mixin) {
  productSource: ProductSource = "POLY";

  mounted(): void {
    // 非首次进入页面时需要在 mounted 中刷新页面
    if (this.ExternalAccessModule.isExternalAccessReady) {
      this.refreshData();
    }
  }

  /**
   * 外部跳转 H5 异步获取 token、channel 的异步请求完成后执行
   * 一定在 mounted 之后
   */
  @Watch("ExternalAccessModule.isExternalAccessReady")
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.refreshData();
    }
  }

  refreshData(): void {
    let productSource = this.$route.query.productSource;
    this.productSource = productSource
      ? (productSource as ProductSource)
      : "POLY";
    (this.$refs["external-access-select-area"] as SelectArea).refreshData();
  }

  /**
   * 选择区域
   *
   * @param param0 分区信息
   */
  selectSection({
    sectionId,
    productId,
    showId,
  }: {
    sectionId: string;
    productId: string;
    showId: string;
  }): void {
    switch (this.ChannelModule.channel) {
      case "plat_ios": // IOS
        break;
      case "plat_android": // Android
        break;
      case "plat_wechat_miniapp": // 小程序
        wx.miniProgram.navigateTo({
          url: `/pagesIndex/seat/seat?sectionId=${sectionId}&showId=${showId}&productId=${productId}&theaterId=${this.TheaterModule.theaterId}`,
        });
        break;
      case "douyin_miniapp": // 抖音小程序
        window.tt.miniProgram.navigateTo({
          url: `/pages/seat/seat?productId=${productId}&showId=${showId}&sectionId=${sectionId}&theaterId=${this.TheaterModule.theaterId}`,
        });
    }
  }
}
